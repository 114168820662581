@import "vars";
@import "responsive";
@import "baseClasses";

/* Variables */
@base-font:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

@form-font: verdona, arial;

/* Colors */
@clrText: black;
@clrBG: white;
@clrBGContrast: #e3e3e3;
@clrHighlight: #C9ECF5;
@clrHighlightContrast: #3C79B4;
@clrDetail: #9b8062;
@clrLink: @clrHighlightContrast;

.clrBase {
	.links();
	background: white;
	color: black;
	border-color: #e3e3e3;
}

.clrContrast {
	.links();
	background: #F6F6F6;
	color: black;
	border-color: #9b8062;

	.title {
		color: #9b8062;
	}
}

.clrHighlight {
	.links();
	background: #C9ECF5;
	color: black;
}

.clrAction {
	background: #7b5;
	color: white !important;
	border-color: #6a4;
}

.links(@clr: #3C79B4) {
	a:link, a:active, a:visited, a:hover {
		color: @clr;
	}
}

.formElem() {
	font-family: @form-font;
}

/* when we want a basic border but we don't want to override the color.
	@side:
		Options:top, right, bottom, or left
*/
.border(@side: top) {
	border-@{side}-style: solid;
	border-@{side}-width: 1px;
}

.borders() {
	.border(top);
	.border(right);
	.border(bottom);
	.border(left);
}

/* Used to hide content in the UI but still make it available to screen readers.*/
.adaHide() {
	position: absolute;
	overflow: hidden;
	height: 0;
	width: 0;
	border: none;
	font-size: 1px;
}

/* Use to override adaHide styling */
.adaShow() {
	position: relative;
	overflow: auto;
	height: auto;
	width: auto;
	font-size: inherit;
}

* {
	box-sizing: border-box;
}

/* Force layout to use full viewport height */
html, body {
	margin: 0;
	padding: 0;
	height: 100vh;
	width: 100%;
	line-height: 1.25rem;

	#root, .siteWrapper {
		min-height: 100vh;
		position: relative;
	}
}

body {
	.clrBase();
	margin: 0;
}

.button,
button {
	.clrAction();
	.borders();
	.formElem();
	display: inline-block;
	padding: .75rem 1rem;
	border-radius: .25rem;
	text-decoration: none;
	cursor: pointer;
}

.error {
	color: red;
}

.success {
	color: green;
}

@-webkit-keyframes animate_ApplyContrast {
    0%    {background: @clrHighlight; color: @clrHighlightContrast;};
    100%  {background: clrBGContrast; color: @clrText;};
}

@keyframes animate_ApplyContrast {
    0%    {background: @clrHighlight; color: @clrHighlightContrast;};
    100%  {background: clrBGContrast; color: @clrText;};
}

@-webkit-keyframes animate_ApplyHighlight {
    0%    {background: clrBGContrast; color: @clrText;};
    100%  {background: @clrHighlight; color: @clrHighlightContrast;};
}

@keyframes animate_ApplyHighlight {
    0%    {background: clrBGContrast; color: @clrText;};
    100%  {background: @clrHighlight; color: @clrHighlightContrast;};
}

.linkTree {
	margin-left: auto;
	margin-right: auto;
	max-width: 400px;

	ul {
		padding-left: 0;

		li {
			display: block;
			margin: .5em 0 0;
			padding: 0;
			text-align: center;

			a {
				display: block;
				margin: 0;
				padding: .5em 1em;
				border: .1em solid @clrHighlightContrast;
				background: @clrBGContrast;
				color: @clrText;
				text-decoration: none;
				font-weight: bold;
				.Animation(animate_ApplyContrast, .3s);

				&:hover {
					background: @clrHighlight;
					color: @clrHighlightContrast;
					.Animation(animate_ApplyHighlight, .3s);
				}
			}
		}
	}
 }

.eldonfox.efmenu {
	&.imploadMobile{
		.menuItems:extend(.absoluteRight) {
			.clrBase();
			border-width: 1px;
			border-style: solid;
			margin-top: 2.5rem;

			@media (min-width: @sizes[tablet]) {
				& {
					margin-top: .25em;
					border-width: 0;
				}
			}

			.menuItem {
				&.nolink,
				a {
					padding: .75em 1.5em .75em;
					text-decoration: none;
				}

				a {
					&:hover {
						&:extend(.clrHighlight);
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.socialMenu {
		.title {
			.adaShow();
		}
	}

	.title {
		.adaHide();
	}

	.menuToggle:extend(.absoluteRight) {
		margin-top: .5rem;
	}

	.menuItems {
		.menuItem {
			text-transform: uppercase;
		}
	}
}

.siteWrapper {
	margin: 0;
	font: @base-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.globalHeader {
		.headerPrimary:extend(.basePadding) {
			.clrBase();
			.border(bottom);

			&.sticky {
				position: fixed;
				top: 0;
				right: 0;
				left: 0;
				z-index: 1000;
			}

			display: flex;
			text-align: left;
			background: white;

			.siteTitle {
				display: none;
			}
		}

		.headerSecondary:extend(.basePadding) {
			.clrHighlight();
			text-align: right;
		}
	}

	.pageWrapper:extend(.baseCenter) {
		.pageBanner:extend(.breakOut) {
			.clrBase();
			.border(bottom);
			position: relative;
			background: transparent url('../../assets/img/banners/home.png') no-repeat 100% 100%;
			background-size: contain;
			height: 130px;

			@media (min-width: @sizes[tablet]) {
				& {
					background: transparent url('../../assets/img/banners/home.png') no-repeat bottom right;
					height: 300px;
				}

				& ~ .triptych {
					position: relative;
					z-index: 300;
					margin-top: -1rem;

					& section {
						box-shadow: 0 0 10px @clrBGContrast;
					}
				}
			}

			.bannerImg {
				display: none;
				width: 100%;
				height: auto;
			}

			.bannerWatermark {
				position: absolute;
				margin: 0;
				top: 20px;
				right: 120px;
				height: 85px;
				width: 120px;
				overflow: hidden;
				text-indent: -100rem;
				background: transparent url('../../assets/img/watermark.svg') no-repeat 100% 100%;
				background-size: contain;

				@media (min-width: @sizes[tablet]) {
					top: 30px;
					right: 550px;
					height: 200px;
					width: 270px;
				}
			}
		}

		.triptych {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: stretch;
			gap: 1rem;
			margin: 1rem 0;

			@media (min-width: @sizes[tablet]) {
				& {
					flex-direction: row;
				}
			}

			section {
				.clrBase();
				border-radius: 1rem;
				border-width: 1px;
				border-style: solid;
				flex: 1;
				padding: 0 1rem 1rem;
			}
		}

		.tinyForm {
			.inputGrid {
				display: grid;
				grid-template-columns: 1em auto 1fr 1fr;
				grid-template-rows: auto;
				row-gap: .5em;
				margin-bottom: .5em;

				label {
					grid-column-start: 1;
					grid-column-end: 3;
					white-space: nowrap;
					margin-right: .5em;
				}

				input {
					grid-column-start: 3;
					grid-column-end: end;
				}
			}

			.textareaSet {
				margin-bottom: .5em;

				label {
					display: block;
				}

				textarea {
					display: block;
					width: 100%;
				}
			}

			.actionBar {
				text-align: right;
			}
		}
	}

	.siteFooter:extend(.basePadding) {
		.clrContrast();
		.border();
		.links(@clrDetail);
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: .5rem;
		padding-bottom: 1.5rem;
		bottom: 0;
		right: 0;
		width: 100%;

		@media (min-width: @sizes[tablet]) {
			& {
				flex-direction: row;
				gap: 5rem;
			}
		}

		.title {
			margin: 0;
			padding: .25em 0 .5em;
			font-size: 1.25rem;
		}

		.listLink, .noLink {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		cite {
			color: @clrDetail;
			font-size: .75rem;
		}
	}
}

@media (min-width: @sizes[tablet]) {
	.siteWrapper {
		.tinyContent {
			margin-right: auto;
			margin-left: auto;
			width: 50vw;
		}

		.siteFooter {
			cite {
				position: absolute;
				right: .25rem;
				bottom: .25rem;
			}
		}
	}
}
