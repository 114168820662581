// out: "./"

@import "../eldonfox/eldonfox.less";

.videoCard {
	.card {
		.cardBody {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.cardImage, .cardDescription {
				flex: 1 1;
			}

			.cardImage {
				img {
					width: 100%;
					max-width: 356px;
					height: auto;
				}
			}
		}

		.cardAction {
			text-align: center;
		}
	}
}

@media (min-width: @sizes[tablet]) {
	.videoCard {
		.card {
			.cardBody {
				flex-direction: row;

				.cardDescription {
					margin: 0 0 0 1em;
				}
			}
		}
	}
}
