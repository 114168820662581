@import 'vars';

.hideMobile {
	@media (max-width: @sizes[largeMobile]) {
		& {
			display: none;
		}
	}
}

.showMobile {
	display: initial;

	@media (min-width: @sizes[largeMobile]) {
		& {
			display: none;
		}
	}
}

.Animation(@name, @time) {
    .AnimationDuration(@time);
    .AnimationName(@name);
}

.AnimationDuration(@time: 1s) {
    -webkit-animation-duration: @time;
    animation-duration: @time;
}

.AnimationName(@name) {
    -webkit-animation-name: @name;
    animation-name: @name;
}
