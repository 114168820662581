// out: "./"

@import "../eldonfox/eldonfox.less";

.eldonfox.efmenu {
	&.imploadMobile{
		.menuItems {
			display: none;
			z-index: 1000;

			.menuItem {
				display: block;
				padding: 0;
				min-width: 20vw;

				&.nolink,
				a {
					padding: .75em 1.5em .75em;
				}

				a {
					display: block;
				}
			}
		}

		.menuToggle {
			display: inline-block;
			border: none;
			background: transparent;
		}
	}

	.menuItems {
		margin: 0;
		padding: 0;
		list-style: none;

		.menuItem {
			display: inline-block;
			padding: .5em 1.5em;
		}
	}
}

@media (min-width: @sizes[tablet]) {
	.eldonfox.efmenu.imploadMobile {
		.menuItems {
			display: inline-block;
			margin: 0;

			.menuItem {
				display: inline-block;
				min-width: 0;
			}
		}

		.menuToggle {
			display: none;
		}
	}
}
