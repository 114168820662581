@import "vars";

.absoluteRight {
	position: absolute;
	right: @baseWOffset;
}

.basePadding {
	padding: @baseHOffset @baseWOffset;
}

.baseCenter {
	margin-right: @baseWOffset;
	margin-left: @baseWOffset;
}

.breakOut {
	margin-right: -@baseWOffset;
	margin-left: -@baseWOffset;
}

@media (min-width: @sizes[largeMobile]) {
	@baseWOffset: 4vw;

	.absoluteRight {
		right: @baseWOffset;
	}

	.basePadding {
		padding: @baseHOffset @baseWOffset;
	}

	.baseCenter {
		margin-right: @baseWOffset;
		margin-left: @baseWOffset;
	}

	.breakOut {
		margin-right: -@baseWOffset;
		margin-left: -@baseWOffset;
	}
}

@media (min-width: @sizes[tablet]) {
	@baseWOffset: 5vw;

	.absoluteRight {
		right: @baseWOffset;
	}

	.basePadding {
		padding: @baseHOffset @baseWOffset;
	}

	.baseCenter {
		margin-right: @baseWOffset;
		margin-left: @baseWOffset;
	}

	.breakOut {
		margin-right: -@baseWOffset;
		margin-left: -@baseWOffset;
	}
}

@media (min-width: @sizes[desktop]) {
	@baseWOffset: 10vw;

	.absoluteRight {
		right: @baseWOffset;
	}

	.basePadding {
		padding: @baseHOffset @baseWOffset;
	}

	.baseCenter {
		margin-right: @baseWOffset;
		margin-left: @baseWOffset;
	}

	.breakOut {
		margin-right: -@baseWOffset;
		margin-left: -@baseWOffset;
	}
}
