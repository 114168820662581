// out: "./"

.socialLink(@position: 0) {
	display: inline-block;
	height: 32px;
	width: 32px;
	background: transparent url('social32.png') 0 @position no-repeat;
	overflow: hidden;
	text-indent: -1000px;
}

.navList.socialMenu {
	.listItems.socialLinks {
		.listItem {
			display: inline-block;
			padding: 0 1em 0 0;
		}

		.facebook {
			.socialLink();
		}

		.linkedIn {
			.socialLink(-64px);
		}

		.twitter {
			.socialLink(-128px);
		}

		.youTube {
			.socialLink(-192px);
		}

		.patreon {
			.socialLink(-256px);
		}

		.instagram {
			.socialLink(-320px);
		}
	}
}
