// out: "./"

.navList {
	.title {
		margin: 0;
		padding: 0;
	}

	.listItems {
		margin: 0;
		padding: 0;
		list-style: none;

		.listItem {
			a, .noLink {
				display: inline-block;
				padding: 0 0 .25em;
				font-size: 1.25em;
			}
		}
	}
}
